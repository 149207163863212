<template>
    <div class="container border-container">
        <div class="info-container">
            <span class="list-title">教学计划</span>
            <el-input v-if="false" class="circular-bead-input" placeholder="请输入班级名称" prefix-icon="el-icon-search" title="按回车键搜索"
                @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model='search.name' >
            </el-input>
        </div>
        <div class="table-container">
            <el-table :data="planListBySchool" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column label="名称" min-width="200" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <div class="fz-bold hover-text-colourful" @click="routerChange(1, scope.row)">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="创建者" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.creator ? scope.row.creator.name : ''}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="50" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                            <el-tooltip placement="top" content="详情">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Core from 'core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            planListBySchool: [],
            multipleSelection: []
        };
    },
    created() {
        this.getPlanList()
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getPlanList()
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getPlanList()
        },
        getPlanList() {
            Core.Api.Plan.list(
                this.currentPage,
                this.pageSize,
                '',
                '',
                2,
                this.schoolId
            ).then((res) => {
                this.total = res.count;
                this.planListBySchool = res.list;
            });
        },
        routerChange(key, q = {}) {
            switch (key) {
                case 1:
                    this.$router.push({
                        path: '/plan/plan-detail',
                        query: {
                            plan_id: q.id,
                            plan_type: 2,
                            can_edit: 0,
                            year: q.year,
                        }
                    });
                    break;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        @include flex(row, space-between, center);
    }
    .dialog-createBySchool {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                .el-select {
                    width: 100%;
                }
            }
        }
    }
}
</style>